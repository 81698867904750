import React, { Component } from 'react';
import Layout from '../components/layout/layout';
import '../stylesheets/pages/about.scss';
import { ContentImage, Cards, Team } from '../components/slices';
import { graphql } from 'gatsby';

class AboutPage extends Component {


  render() {

    const data = this.props.data.allPrismicPage.edges[0].node.data;
    const header = this.props.data.header.data;
    const footer = this.props.data.footer.data;

    const content = data.body.map((slice, index) => {
      switch (slice.slice_type) {
        case 'thirds_again':
          return <ContentImage key={`ContentImageSlice-${index}`} sliceData={slice} />;
        case 'cards':
          return <Cards key={`CardsSlice-${index}`} sliceData={slice} />;
        case 'team':
          return <Team key={`TeamSlice-${index}`} sliceData={slice} />
        default:
          return null;
      };
    });

    let backgroundColorStyle = {
      backgroundColor: ""
    };

    if (data.header_style === "dark") {
      backgroundColorStyle = {
        backgroundColor: "#121112"
      };
    } else if (data.header_style === "split") {
      backgroundColorStyle = {
        background: `linear-gradient(to right, #fff 50%, #121112 50%)`
      }
    }

    return (
      <Layout headerStyle={data.header_style} title={data.meta_title.text} description={data.meta_description.text} header={header} footer={footer} >
        <div className="about-page" style={backgroundColorStyle}>
          {content}
        </div>
      </Layout>
    )
  }

}


export default AboutPage


export const query = graphql`
{
  allPrismicPage {
      edges {
        node {
          uid
          data {
            header_style
            meta_description {
              text
            }
            meta_title {
              text
            }
            page_title {
              text
            }
            body {
              ... on PrismicPageDataBodyCards {
                id
                slice_type
                primary {
                  content {
                    richText
                    text
                  }
                  slice_heading {
                    text
                  }
                  background_image {
                    alt
                    url
                  }
                }
                items {
                  card_content {
                    richText
                    text
                    html
                  }
                  card_title {
                    text
                    richText
                    html
                  }
                }
              }
              ... on PrismicPageDataBodyTeam {
                id
                slice_type
                items {
                  hover_image {
                    alt
                    url
                  }
                  image {
                    alt
                    url
                  }
                  name {
                    text
                  }
                  position {
                    text
                  }
                  text {
                    text
                  }
                }
                primary {
                  slice_heading {
                    text
                  }
                  team_image_1 {
                    alt
                    url
                  }
                  team_image_2 {
                    alt
                    url
                  }
                  team_image_3 {
                    alt
                    url
                  }
                }
              }
              ... on PrismicPageDataBodyThirdsAgain {
                id
                slice_type
                primary {
                  slice_heading {
                    text
                  }
                }
                items {
                  show_shapes_
                  section_layout
                  left_column_heading_1 {
                    text
                  }
                  left_column_heading_2 {
                    text
                  }
                  left_column_heading_color_1
                  left_column_heading_color_2
                  left_column_text_1 {
                    html
                    text
                  }
                  left_column_text_2 {
                    html
                    text
                  }
                  right_column_heading_1 {
                    text
                  }
                  right_column_heading_2 {
                    text
                  }
                  right_column_heading_color_1
                  right_column_heading_color_2
                  right_column_text_1 {
                    html
                    text
                  }
                  right_column_text_2 {
                    html
                    text
                  }
                  section_heading {
                    text
                  }
                  section_image {
                    alt
                    url
                  }
                }
              }
            }
          }
        }
      }
    }

   header: prismicNavigation {
    ...FragmentHeader
  }

      footer: prismicFooter {
      ...FragmentFooter
    }
}
`
